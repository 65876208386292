.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Dim the background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it is above other content */
}

.modal-content {
  border: 40px solid transparent; /* Adjust for the size of the border area in the image */
  border-image: url('/public/miscAssets/popupbg.png') 40 round;
  border-radius: 18px;
  position: relative;
  width: 80%; /* Or any specific width */
  max-width: 1000px; /* Optional, for wider screens */
  color: rgb(253, 250, 243);
}

.modal-content-fill {
  background: radial-gradient(ellipse 50% 40% at center, rgb(44, 44, 44) 5%, rgba(30, 30, 30, 1) 40%, rgb(0, 0, 0) 100%);
}

.title {
  text-align: center;
}

.divider {
  width: 100%;
}

.subdivider {
  width: 100%;
  scale: .8;
}

.settings-header {
  font-size: 20px;
  margin-bottom: 4px;
}

.background-select {
  margin: auto;
}

.toggle-inbuttons-div {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  padding: 3px;
  display: inline-flex;
  gap: 7px;
  margin-bottom: 4px;
}

.toggle-iconnames-div button {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
}

.toggle-iconnames-div button:hover {
  background-color: #d4d4d4;
}


.background-options-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px; /* Spacing between preview images */
}

.background-option {
  width: 100px; /* Adjust width */
  height: 60px; /* Adjust height */
  background-size: cover;
  background-position: center;
  border: 2px solid transparent;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.2s, border 0.2s;
}

.background-option:hover {
  transform: scale(1.1); /* Slight zoom effect on hover */
}

.background-option.selected {
  border: 2px solid #00ff00; /* Highlight border for selected background */
}


.close-button {
  color: white;
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 24px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .modal-content-fill {
    background: radial-gradient(ellipse 40% 50% at center, rgb(44, 44, 44) 5%, rgba(30, 30, 30, 1) 50%, rgb(0, 0, 0) 100%);
  }
}