.main-game {
  margin: auto; /* This centers the main-game div on the page */
  margin-top: 0;
  text-align: center;
  justify-content: center;
  width: 100%;
  padding: 5%;
  padding-bottom: 2%;
  padding-top: 0%;
}

.loading-screen {
  position: relative;
  margin: auto;
  margin-top: 30vh;
  width: 80px;
  height: 116px;
}

.loading-image {
  position: absolute;
  top: 0;
  left: 0;
  scale: 1.5;
  animation: blink-in-out .5s steps(1) infinite alternate; /* Adjust the duration and timing */
}

.image-1 {
  animation-delay: 0s;
}

.image-2 {
  visibility: hidden;
  animation-delay: .5s; /* Half the duration to alternate */
}

@keyframes blink-in-out {
  0%, 49.9% {
    visibility: visible;
  }
  50%, 100% {
    visibility: hidden;
  }
}


.game-container {
  display: flex;
  align-items: center; /* Align items vertically */
  justify-content: center;
  margin: auto;
  margin-bottom: 2%;
  margin-top: 2%;
  height: auto;
}

@keyframes fadeflip {
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(180deg);
  }
}

.character-display {
  justify-content: center;
  align-items: center;
  border-image:  url('/public/miscAssets/extracted_image_291.png');
  background: url('/public/miscAssets/op_choose_bg.png') no-repeat center/cover;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  border-radius: 7px;
  perspective: 1000px;
  overflow: hidden;
  z-index: 10;
}

.solution-reveal {
  background: none;
  box-shadow: none;
  overflow: visible;
}

.solution-reveal .solution-container {
  box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.5);
  animation: fadeflip .8s ease-in-out .3s backwards;

}

.frontside {
  transform: rotateY(180deg);
  backface-visibility: hidden;
}

.backside {
  position: absolute;
  margin: auto;
  left: 0;
  top: 0;
  width: 100%;  
  height: 100%;
  background: url('/public/miscAssets/op_choose_bg.png') no-repeat center/cover;
  backface-visibility: hidden;
  object-fit: contain;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* temp fix to animation bug */
}

.solution-container {
  position: relative;
  padding: 2%;
  border-radius: 7px;
  background: linear-gradient(
    rgba(0, 0, 0, 0.4), 
    rgba(0, 0, 0, 0.0)
  ), url('/public/miscAssets/extracted_image_6328.png') no-repeat center/cover;
  transform-style: preserve-3d;
  transition: transform 0.8s;
  transform: rotateY(180deg);
}

/* CSS for the skin button {... */
.skin-button {
  position: absolute;  /* Position the button relative to its nearest positioned ancestor */
  top: 10px;
  left: 10px;
  width: 15%;
  max-width: 45px;
  padding: 0;
  border: 1px solid #182046;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0.5; /* Adds a slight transparency on hover */
}

.skin-button-icon {
  width: 100%;
  height: auto; /* Maintains the aspect ratio of the image */
  display: block;
  border-radius: 0;
}

.skin-button:hover {
  outline: none; /* Optional: removes the outline */
  opacity: 1;
}

.skin-button:active {
  opacity: 0.6; /* Adds more transparency when clicked */
}
/* ...} */

.character-imagesol {
  width: 100%;
  max-height: 100vh;
}

.character-image-blank {
  width: 18vw;
  height: auto;
}

.game-info {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  background: linear-gradient(
    rgba(0, 0, 0, 0.2), 
    rgba(0, 0, 0, 0.0)
  ), url('/public/miscAssets/op_choose_bg.png') no-repeat center/cover;
  width: 50vw;
  height: auto;
  margin-left: 2%;
  padding: 1%;
}

.mobile-info-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mobile-info {
  margin: auto;
  padding-left: 1%;
  padding-right: 1%;
}

.gamemodetitle-text {
  color: #ffffff;
  font-size: 36px;
}

.tries-text {
  font-size: 28px;
  margin: auto;
  margin-top: 3px;
  color: #ffffff;
}

.health-bar img{
  margin-left: -3px;
  margin-right: -3px;
}

.heart {
  transition: opacity 0.5s ease-in-out;
  opacity: 1;  /* Ensure it's fully visible by default */
}

.streak-info {
  background-color: #7f95c2;
  border: 3px solid #6a7ca1;
  display: inline-block;
  margin: auto;
  margin-bottom: 15px;
  border-radius: 4px;
  padding: 2px;
}

.streak-info p {
  margin: auto;
  padding: 1px;
}


@keyframes tilt {
  0%, 100% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(10deg);
  }
}

@keyframes tilt2 {
  0%, 100% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(-10deg);
  }
}

.emoji1 {
  animation: tilt 1s steps(1) infinite alternate;
}

.emoji2 {
  animation: tilt2 1s steps(1) infinite alternate;
}


.end-message-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin: auto;
  padding-top: 10px;
}

.end-message-container img {
  height: 100%;
  max-height: 84px;
  height: clamp(55px, 85px);
  margin-bottom: 2%;
}

.end-screen-incorrect {
  background: rgba(192, 5, 5, 0.2);
  border-radius: 5px;  
}

.end-screen-correct {
  background: rgba(7, 255, 81, 0.2);
  border-radius: 5px;  
}

.end-message-text-container {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 2px;
  padding-right: 2px;
  margin: auto;
  color: white;
}

.end-message-text {
  font-size: clamp(13px, 1.2vw, 22px);
}

.end-message-text2 {
  color: white;
  font-size: clamp(14px, 1.3vw, 22px);
}

.end-message-name {
  padding-top: 2px;
  color: white;
  font-size: clamp(14px, 2vw, 22px);
  font-style: italic;
}

/* summary table css */
.summary-table table{
  background-color: #8f9aaf;
  border: 3px solid #333;
  padding: 4px;
  border-radius: 3px;
  display: inline-block;
  margin: auto;
  table-layout: fixed; /* This helps in keeping the cells to a fixed size */
}

.tablecaption {
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: x-large;
}

.tablecaption img{
  height: 40px;
  margin-left: 5px;
}

.summary-table td {
  width: 17px; /* Width of each cell */
  height: 17px; /* Height of each cell, same as width to make it square */
  padding: 5px;
}


.guess-input {
  width: 100%; /* Control the width of this container */
  height: auto;
  margin: auto; /* Center the container horizontally on the page */
  position: relative; /* Set positioning context for the dropdown */
  justify-content: center; /* Center children vertically */
}

.input-button-wrapper {
  display: flex;
  align-items: center; /* Align items vertically */
}

.guess-input input {
  width: 100%;
  margin-right: 8px; /* Space between input and button */
  padding: 8px;
  font-size: 16px;
}

@keyframes expand {
  0% {
    height: 0%;
  }
  100% {
    height: 1000%;
  }
}

.dropdown-container {
  position: absolute;
  top: 105%; /* Position directly below the input field */
  left: 0; /* Align to the left edge of the guess-input container */
  right: 0; /* Align to the right edge of the guess-input container */
  display: flex;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 400px;
  z-index: 10;
  animation: expand .2s ease-in-out;
}

.filter-container {
  display: flex;
  flex-direction: column;
  background-color: #333; /* Adjust to match your theme */
  padding: 4px;
  overflow: auto;
  width: 60%;
}

.filter-category {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4px;
  padding: 4px;
  border: 2px solid rgb(145, 98, 55);
}

.filter-category-title {
  color: #fff;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 16px;
  padding-bottom: 2px;
}

.filter-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.filter-icon {
  background: #947474;
  border: none;
  cursor: pointer;
  padding: 3px;
  margin: 1px;
  color: white;
  border: 1px solid #202020;
  opacity: .5;
}

.filter-icon:hover {
  border: 1px solid rgb(145, 98, 55);
}

.filter-icon.active {
  background: none;
  opacity: 1;
  /* border: 1px solid #649b5a; */
  border-radius: 4px;
  color: white;
}

.filter-icon img {
  width: 24px; /* Adjust size as needed */
  height: auto;
}

.autocomplete-dropdown {
  background: url('/public/miscAssets/base_unit_blue.png') no-repeat center/cover;
  list-style-type: none;
  border-radius: 4px;
  padding: 0;
  margin-top: 0px;
  margin-bottom: 0px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  overflow-y: auto;
  z-index: 10;
}

/* Styles the scrollbar track */
::-webkit-scrollbar-track {
  background-color: #19244b; /* Light grey background on the track */
  border-radius: 10px; /* Adds rounded corners to the track */
}

/* Styles the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #3a3e7a; /* Dark grey scroll thumb */
  border-radius: 10px; /* Rounded corners on the scroll thumb */
  border: 3px solid #444381; /* Adds a light grey border around the thumb */
}

/* Styles the scrollbar itself (width, etc.) */
::-webkit-scrollbar {
  width: 8px; /* Width of the vertical scrollbar */
  height: 8px; /* Height of the horizontal scrollbar */
}

/* Optional: Style for scrollbar buttons (arrows at the ends of the scrollbar) */
::-webkit-scrollbar-button {
  height: 6px; /* Height of the buttons */
  background-color: #2c4cb4; /* Color of the buttons */
}

/* Optional: Style for the scrollbar corner (where horizontal and vertical scrollbars meet) */
::-webkit-scrollbar-corner {
  background-color: black; /* Color of the corner */
}


.autocomplete-dropdown li {
  margin: 5px;
  margin-right: 7px;
  padding: 10px 20px; /* adequate padding for touch targets */
  display: flex;
  align-items: center; /* vertically align items */
  cursor: pointer;
  font-size: larger;
  color: white;
  background-color: #2222226c;
  border: 3px solid #292a31bb;
}

.autocomplete-dropdown li:hover {
  background-color: #2e2e2ea2; /* light grey background on hover */
}

.autocomplete-dropdown img {
  width: 55px;
  height: 55px;
  margin-right: 10px;
}

/* .autocomplete-dropdown li:nth-child(even) {
  background-color: #fcfcfc;
} */

.prevdailysolutiontext {
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3px;
}

.prevdailysolutiontext p {
  margin-top: auto;
  margin-bottom: auto;
  color: white;
  font-weight: bold;
}

.prevdailysolutiontext img {
  width: 33px;
  height: 33px;
  padding-left: 5px;
}



/* table stuff */

.character-imageicon {
  width: 55px;
  height: 55px;
  /* space between image and text */
  object-fit: cover;
  /* ensure images cover the area nicely */
}

.character-regionicon {
  width: 50px;
  height: 50px;
  /* space between image and text */
  object-fit: cover;
  /* ensure images cover the area nicely */
}

/* guess table css */

.guess-table table {
  width: 100%;
  border-spacing: 4px 4px;
  background-color: #272626;

  font-family: roboto, Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-shadow: 1.5px 1px 0 #2b2b2b; /* Creates a simple black outline */
  letter-spacing: .3px;
  border-radius: 3px;
}

.guess-table th, .guess-table td {
  text-align: center;  /* Centers text in table cells */
  padding: 5px;
  box-sizing: border-box;
  transition: width 2s;
}

.guess-table td {
  color: #fff;  /* Ensures text color contrasts well with the black outline */
  font-weight: 700; /* Makes the text thicker */
  font-size: 23px;
}

.guess-table th {
  background-color: #2d3c58;  /* Green background for headers */
  border: 3px solid #3c6abe;
  color: white;
}

.guess-table tr:hover {
  background-color: #f5f5f5;
  transition: background-color 0.3s ease;
}

.guess-table td:nth-child(1) { /* First column for photos */
  width: 80px; /* Fixed width */
  text-align: center;
}

.celldiv {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  transform-style: preserve-3d;
  width: 100%;  
  height: 80px;
  will-change: transform;
}

.front, .back {
  backface-visibility: hidden;
}

.back {
  position: absolute;
  margin: auto;
  width: 100%;  
  height: 100%;   
  transform: rotateY(180deg);
  background-color: #272626;
  object-fit: contain;
  outline: 10px solid #706b6b;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.back img {
  width: 65px;
}

/* table cell's background colors */
.iconname-container {
  display: flex;         /* Establishes a flex container */
  flex-direction: column; /* Stacks the flex items vertically */
  align-items: center;    /* Aligns items in the center horizontally within the container */
  justify-content: center; /* Aligns items in the center vertically within the container */
}

.iconname {
  padding: 0;
  margin: auto;
  font-size: smaller;
}

.date-container {
  display: flex;         /* Establishes a flex container */
  flex-direction: column; /* Stacks the flex items vertically */
  align-items: center;    /* Aligns items in the center horizontally within the container */
  justify-content: center; /* Aligns items in the center vertically within the container */
}

.date-container img {
  width: 30px;  /* Set to your desired width */
  height: auto; /* Maintains the aspect ratio of the image */
  margin-left: 8px;
  margin-right: 8px;
}

.photo-cell {
  background-color: #575757;
  border: 4px solid #706b6b;
}

.correct-answer {
  background-color: #649b5a;
  border: 4px solid #53bd40;
}

.incorrect-answer {
  background-color: #a33939;
  border: 4px solid #ce3232;
}

.score-cell {
  background-color: #575757;
  border: 3px solid #222222;
}

.correct-score {
  background-color: #53bd40;
  border: 3px solid #222222;
}

.incorrect-score {
  background-color: #ce3232;
  border: 3px solid #222222;
}

@keyframes flipcell {
  0% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(0);
  }
}

.last-row-animated .celldiv {
  animation: flipcell 0.85s ease-in-out both;
}

.last-row-animated td:nth-child(0) .celldiv { animation-delay: 1.20s; }
.last-row-animated td:nth-child(1) .celldiv { animation-delay: 1.05s; }
.last-row-animated td:nth-child(2) .celldiv { animation-delay: 0.90s; }
.last-row-animated td:nth-child(3) .celldiv { animation-delay: 0.75s; }
.last-row-animated td:nth-child(4) .celldiv { animation-delay: 0.60s; }
.last-row-animated td:nth-child(5) .celldiv { animation-delay: 0.45s; }
.last-row-animated td:nth-child(6) .celldiv { animation-delay: 0.30s; }
.last-row-animated td:nth-child(7) .celldiv { animation-delay: 0.15s; }
.last-row-animated td:nth-child(8) .celldiv { animation-delay: 0.00s; }


input[type="text"] {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-right: 5px;  /* Ensures spacing between elements */
}

input::placeholder {
  font-size: 16px; /* Adjust this value as needed */
}

.guessbtn {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #4CAF50;  /* Green to match the theme */
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.guessbtn:hover {
  background-color: #45a049;  /* Darker green on hover */
}

.play-again-button {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #f44336;  /* Red for contrast on important action */
  color: white;
}

.play-again-button:hover {
  background-color: #dc2836;  /* Slightly darker red on hover */
}

.feedback {
  font-size: 16px;
  color: #d9534f;  /* Red color for errors */
  margin-top: 10px;  /* Space from the input area */
}


/* debug area */
.debug-container {
  position: relative;
  padding: 3px;
  height: 20px; /* or whatever height you prefer */
}

.debug-info {
  position: absolute;
  left: 0;
  width: 100%;
  color: transparent;
  text-align: center;
}

/* mobile device css */
@media (max-width: 768px) {
  .main-game {
    padding: 1%;
  }

  .game-container {
    justify-content: center;
  }

  .character-display {
    display: flex;
  }

  .solution-container {
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0);
  }

  .character-image-blank {
    width: 30vw;
  }

  .character-imagesol {
    width: 40vw;
    height: auto;
  }

  .game-info {
    padding: 2%;
    margin-left: 0%;
    width: 85vw;
  }
  
  .gamemodetitle-text {
    font-size: 22px;
  }

  .tries-text {
    font-size: 20px;
  }

  .health-bar-mobile {
    margin-top: 3px;
  }

  .streak-info {
    font-size: 12px;
  }

  .guess-input {
    margin-top: 2%;
  }

  .autocomplete-dropdown li {
    font-size: 14px;
    padding: 3%;
    margin: 2%;
  }

  .autocomplete-dropdown img {
    width: 25px;
    height: auto;
  }

  .filter-container {
    width: 70%;
    padding: 2px;
  }

  .filter-category-title {
    margin-right: 0px;
  }

  .filter-icons {
    border-left: 1px solid rgba(145, 99, 55, 0);
    padding-left: 0px;
    justify-content: center;
  }

  .filter-icon:hover {
    border: none;
  }

  .prevdailysolutiontext p {
    color: white;
    font-size: 12px;
  }
  
  .prevdailysolutiontext img {
    width: 23px;
    height: 23px;
    padding-left: 5px;
  }

  .guess-table table {
    font-weight: 200;
    font-style: small;
    border-spacing: 3px 3px;
  }

  .guess-table td {
    font-weight: 700; /* Makes the text thicker */
    font-size: 12px;
    padding: 0;
  }

  .guess-table th {
    font-weight: bold; /* Makes the text thicker */
    font-size: 8px;
    padding: 0;
  }

  .photo-cell {
    max-width: 50px;
    background-color: #575757;
    border: 3px solid #706b6b;
  }
  
  .correct-answer {
    background-color: #649b5a;
    border: 3px solid #53bd40;
  }

  .incorrect-answer {
    background-color: #a33939;
    border: 3px solid #ce3232;
  }

  .back {
    outline: 4px solid #706b6b;
  }

  .back img {
    width: 40px;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .photo-cell img{
    max-width: 100%;
    height: auto;
  }
}

@media (prefers-reduced-motion: reduce) {
  .emoji1, .emoji2, .last-row-animated .celldiv {
    animation: none;
  }
}