.header {
  background: linear-gradient(
    rgba(0, 0, 0, 0.5), 
    rgba(0, 0, 0, 0.0)
  );
  background-color: #333;
   color: white;
   padding: 0 15px;  /* Adjust padding to fit all items properly */
   width: 100%;
   height: 6.3vh;
   display: flex;
   justify-content: space-between;
   align-items: center;  /* Ensure vertical centering of children */
   box-sizing: border-box;
   position: fixed;
   top: 0;  /* Ensure header is at the top */
   z-index: 20;
}

/* Style the navigation container */
.navigation {
  display: flex;
  justify-content: center;  /* Center the links horizontally */
  align-items: center;  /* Align the links vertically */
  gap: 3px;  /* Space between links */
}

/* Style the individual links */
.nav-link {
  text-decoration: none;  /* Remove underline from links */
  color: #ffffff;  /* Set the text color to white */
  font-size: 18px;  /* Increase font size */
  font-weight: bold;  /* Make text bold */
  padding: 10px 10px;  /* Add some padding around text for clickable area */
  border-radius: 8px;  /* Add rounded corners */
  transition: all 0.3s ease;  /* Smooth transition for hover effect */
}

/* Add a hover effect to the links */
.nav-link:hover {
  background-color: #3f3f3f;  /* Darken background on hover */
  color: #f0f0f0;  /* Lighten text color on hover */
  transform: scale(1.05);  /* Slightly enlarge the link when hovered */
}

.infobtn {
  height: 100%;
  color: white;
  cursor: pointer;
  background: none;
  border: none;
  margin-top: 0;  /* Adjust margin-top if necessary */
  padding: 2px 2px; /* Adjust padding for better spacing */
  display: flex;  /* Add display flex */
  align-items: center;  /* Align icon vertically */
  justify-content: center;  /* Center content horizontally */
}

.infobtn:hover {
  background: linear-gradient(
    rgba(0, 0, 0, 0.5), 
    rgba(0, 0, 0, 0.2)
  );
}

.info-button-icon {
  height: 50%;
  width: auto;
  padding-right: 4px;
  padding-left: 4px;
}

.settingsbtn {
  height: 100%;
  color: white;
  cursor: pointer;
  background: none;
  border: none;
  margin-top: 0;  /* Adjust margin-top if necessary */
  padding: 2px 2px; /* Adjust padding for better spacing */
  display: flex;  /* Add display flex */
  align-items: center;  /* Align icon vertically */
  justify-content: center;  /* Center content horizontally */
}

.settingsbtn:hover {
  background: linear-gradient(
    rgba(0, 0, 0, 0.5), 
    rgba(0, 0, 0, 0.2)
  );
}

.settings-button-icon {
  height: 90%;
  width: auto;
}

h1 {
  font-size: 28px;
  margin: auto;
}

.versiontext {
  padding-left: 4px;
}


@media (max-width: 768px) {
  .header {
    padding: 0 5px;
  }

  .navigation {
    width: 28%;
    display: flex;
    justify-content: center;  /* Center the links horizontally */
    align-items: center;  /* Align the links vertically */
    gap: 0px;  /* Space between links */
  }

  .nav-link {
    text-decoration: none;  /* Remove underline from links */
    color: #ffffff;  /* Set the text color to white */
    font-size: 12px;  /* Increase font size */
    font-weight: bold;  /* Make text bold */
    padding: 0px 0px;  /* Add some padding around text for clickable area */
    border-radius: 8px;  /* Add rounded corners */
    transition: all 0.3s ease;  /* Smooth transition for hover effect */
  }
  
  /* Add a hover effect to the links */
  .nav-link:hover {
    background: linear-gradient(
    rgba(0, 0, 0, 0.5), 
    rgba(0, 0, 0, 0.0)
    );
    background-color: #333;  /* Darken background on hover */
    transform: scale(1);  /* Slightly enlarge the link when hovered */
  }

  .versiontext {
    font-size: 10px;
  }
}