.maingame-container {
  display: flex;
  width: 80%;
  height: 100vh;
  padding-top: 6.3vh;
  background: rgba(87, 87, 87, 0.699);
  border: 1px solid rgba(61, 61, 61, 0.9);
  background-attachment: fixed;
  overflow-y: auto; /* Allows scrolling */

  /* Hides scrollbar in IE and Edge */
  -ms-overflow-style: none;
}

@media (max-width: 768px) {
  .maingame-container {
    width: 100%; /* Sets width to 100% for devices with a screen width of 768px or less */
    background: rgba(87, 87, 87, 0.521);
  }
}

.maingame-container::-webkit-scrollbar {
  display: none;
}

select {
  padding: 10px;
  margin: 20px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}