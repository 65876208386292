.countdown-timer {
    color: white;
}

.timertext {
    font-size: 22px;
    margin: auto;
}

.timertime {
    font-size: 24px;
    padding-bottom: 5px;
    margin: auto;
}

@media (max-width: 768px) {
    .timertext {
        font-size: 12px;
    }
    
    .timertime {
        font-size: 14px;
    }
}