.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Dim the background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it is above other content */
  }
  
  .modal-content-info {
    border: 40px solid transparent; /* Adjust for the size of the border area in the image */
    border-image: url('/public/miscAssets/popupbg.png') 40 round;
    border-radius: 18px;
    position: relative;
    width: 80%; /* Or any specific width */
    max-width: 500px; /* Optional, for wider screens */
    color: rgb(253, 250, 243);
  }

  .info-title {
    text-align: center;
  }

  .info-text {
    margin: 0;
    margin-top: 7px;
  }
  
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 24px;
    cursor: pointer;
  }